import {AxiosResponseHeaders} from "axios/index";

export interface Pagination {
    page: number
    limit: number
    count: number
    totalPage: number
    totalCount: number
}

export const collectPagination = (headers: AxiosResponseHeaders): Pagination => ({
    page: +headers['pagination-page'],
    limit: +headers['pagination-limit'],
    count: +headers['pagination-count'],
    totalPage: +headers['pagination-total-page'],
    totalCount: +headers['pagination-total-count']
});

// export function collectPaginationSerialNumber({page, limit, count, totalPage, totalCount}, position) {
//     return (+page - 1) * +limit + +position
// }