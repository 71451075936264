import React from 'react'
import {Box, ChakraProps, HStack, Stack, Text} from "@chakra-ui/react";

const AppFooter = (props: ChakraProps) => {
    return (
        <Box {...props}>
            <HStack justifyContent={'space-between'}>
                <Stack spacing={'0'} alignItems={'start'} color={'gray.300'} fontSize={'xs'}>
                    <Text>ФГБОУ ВО Нижневартовский государственный университет</Text>
                    <Text>{`1988 - ${new Date().getFullYear()}`}</Text>
                </Stack>
                <Stack spacing={'0'} alignItems={'end'} color={'gray.300'} fontSize={'xs'}>
                    <Text textAlign={'end'}>Левкин Лев Владимирович</Text>
                    <Text textAlign={'end'}>dremamax@gmail.com</Text>
                </Stack>
            </HStack>
        </Box>
    )
}

export default AppFooter