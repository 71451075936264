import React, {useCallback, useContext, useEffect, useState} from 'react'
import {
    Box,
    ChakraProps,
    Container,
    Divider,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spinner,
    Stack,
    Text,
    useDisclosure,
    UseModalProps,
    useToast
} from "@chakra-ui/react";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import NotificationListFragment from "./fragments/NotificationListFragment";
import LoginFragment from "./fragments/LoginFragment";
import {Navigate, Route, RouteProps, Routes} from "react-router-dom";
import ServerService from "./services/ServerService";
import {UserSession} from "./entities/UserSession";
import UserSessionContext from "./context/UserSessionContext";
import UserSessionControlContext from "./context/UserSessionControlContext";
import {UserSessionControl} from "./entities/UserSessionControl";
import AccountListFragment from "./fragments/AccountListFragment";

const AppScreen = (props: ChakraProps) => {
    //initial components
    const toast = useToast()

    //initial states
    const [userSession, setUserSession] = useState<UserSession | null>(null)
    const [userSessionIsLoading, setUserSessionIsLoading] = useState<Boolean>(false)
    const unLoginProgressModalController = useDisclosure()

    //initial actions
    const onLoadSession = useCallback(() => {
        setUserSession(null)
        setUserSessionIsLoading(true)

        ServerService.session()
            .then(({data}) => setUserSession(data))
            .catch((error) => {
                const {status} = error.response

                if (status === 401) {
                    setUserSession({})
                    return
                }
            })
            .finally(() => setUserSessionIsLoading(false))
    }, [])
    const onClearSession = useCallback(() => {
        unLoginProgressModalController.onOpen()

        ServerService.unLogin()
            .then(() => setUserSession({}))
            .catch((error) => {
                toast({
                    title: `Ошибка авторизации выхода из аккаунта`,
                    status: 'error',
                    position: 'top'
                })
            })
            .finally(() => unLoginProgressModalController.onClose())
    }, [])

    //initial effects
    useEffect(() => {
        onLoadSession()
    }, [])

    //initial props
    const userSessionControl: UserSessionControl = {
        onLoadSession,
        onClearSession
    }

    //build view
    return (
        <UserSessionContext.Provider value={userSession}>
            <UserSessionControlContext.Provider value={userSessionControl}>
                <>
                    <Box position={'fixed'} top={'0'} left={'0'} right={'0'} bottom={'0'} background={'#f4f7fb'} userSelect={'none'} {...props}>
                        <Container maxW={'1920px'} h={'100%'} mx={'auto'}>
                            <Stack spacing={{base: '4', lg: '5'}} divider={<Divider/>} h={'100%'} px={{base: '0', lg: '5'}} py={'5'}>
                                <AppHeader/>
                                <Flex pos={'relative'} flex={'1 1 0'}>
                                    {userSessionIsLoading && <Spinner thickness='8px' speed='0.65s' emptyColor='gray.200' color='#1f4d7f' size='xl' m={'auto'}/>}
                                    {userSession && (userSession._id ? <SessionRoutes/> : <UnSessionRoutes/>)}
                                </Flex>
                                <AppFooter/>
                            </Stack>
                        </Container>
                    </Box>
                    {unLoginProgressModalController.isOpen && <UnLoginProgressModal {...unLoginProgressModalController}/>}
                </>
            </UserSessionControlContext.Provider>
        </UserSessionContext.Provider>
    )
}

export default AppScreen

const SessionRoutes = (props: RouteProps) => {
    const userSession = useContext(UserSessionContext)

    return (
        <Routes {...props}>
            <Route path={'notifications'} element={<NotificationListFragment h={{base: '100%', lg: '97.5%'}} my={'auto'}/>}/>
            {userSession?.role === 'Admin' &&
                <Route path={'accounts'} element={<AccountListFragment h={{base: '100%', lg: '97.5%'}} my={'auto'}/>}/>
            }
            <Route path={'*'} element={<Navigate to={'notifications'} replace/>}/>
        </Routes>
    )
}

const UnSessionRoutes = (props: RouteProps) => (
    <Routes {...props}>
        <Route path={'login'} element={<LoginFragment my={'auto'} pb={'5%'}/>}/>
        <Route path={'*'} element={<Navigate to={'login'} replace/>}/>
    </Routes>
)

const UnLoginProgressModal = (props: UseModalProps) => {
    return (
        <Modal
            isCentered
            autoFocus={false}
            returnFocusOnClose={false}
            lockFocusAcrossFrames={true}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            {...props}>
            <ModalOverlay bg={'blackAlpha.600'} backdropFilter={'blur(10px)'}/>
            <ModalContent w={'max-content'} rounded={'md'} shadow={'base'} userSelect={'none'}>
                <ModalBody px={'5'} py={'3'}>
                    <HStack spacing={4} justifyContent={'center'}>
                        <Spinner
                            thickness='2px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='gray.600'
                            size='md'/>
                        <Text
                            color={'gray.700'}
                            fontSize={'sm'}
                            fontWeight={'thin'}
                            textAlign={'center'}
                            textTransform={'uppercase'}>Выход из аккаунта</Text>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}