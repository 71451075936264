import React, {useCallback, useEffect, useState} from 'react';
import {ChakraProps, Container, Divider, Heading, HStack, IconButton, SkeletonText, Stack, Tooltip, useToast} from "@chakra-ui/react";
import AddIcon from "../components/icons/AddIcon";
import {User} from "../entities/User";
import ServerService from "../services/ServerService";
import AccountItem from "../components/AccountItem";
import useController from "../hooks/ControllerHook";
import AccountDeleteModal from "../components/AccountDeleteModal";
import AccountEditorModal, {AccountEditorMode} from "../components/AccountEditorModal";
import AccountGeneratePasswordModal from "../components/AccountGeneratePasswordModal";
import BackIcon from "../components/icons/BackIcon";
import {useNavigate} from "react-router-dom";

interface AccountWithEditorMode {
    account: User | null,
    mode?: AccountEditorMode
}

const AccountListFragment = (props: ChakraProps) => {
    //initial components
    const toast = useToast()
    const navigate = useNavigate()

    //initial states
    const [users, setUsers] = useState<Array<User>>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const modalEditController = useController<AccountWithEditorMode>()
    const modalGeneratePasswordController = useController<User>()
    const modelDeleteController = useController<User>()

    //initial actions
    const onLoadUsersRequest = useCallback(() => {
        setIsLoading(true)
        setUsers([])

        ServerService.getUsers()
            .then(({data}) =>
                setUsers(
                    data.sort((a: User, b: User) => {
                        if (a.role === "Admin" && b.role !== "Admin")
                            return -1
                        else if (a.role !== "Admin" && b.role === "Admin")
                            return 1
                        else
                            return a.fullName.localeCompare(b.fullName);
                    })
                )
            )
            .catch(() => {
                toast({
                    title: 'Ошибка загрузки списка пользователей',
                    description: 'Проверьте подключение к сети',
                    status: 'error',
                    position: 'top'
                })
            })
            .finally(() => setIsLoading(false))
    }, [])

    //initial effects
    useEffect(() => {
        onLoadUsersRequest()
    }, [])

    //build view
    return (
        <>
            <Container maxW={'container.xl'} p={'0'} {...props}>
                <Stack spacing={0} minH={'100%'} overflow={'hidden'} px={{base: '4', lg: '6'}} bg={'white'} borderRadius={'lg'} shadow={'base'}>
                    <HStack spacing={'3'} zIndex={'100'} minH={'64px'} justifyContent={'space-between'}>
                        <HStack spacing={'3'}>
                            <Tooltip hasArrow label={'Вернуться к списку уведомлений'} placement={'bottom'}>
                                <IconButton
                                    onClick={() => navigate('/notifications')}
                                    variant={'unstyled'}
                                    role={'group'}
                                    icon={
                                        <BackIcon
                                            w={'24px'} h={'24px'}
                                            stroke={'gray.200'}
                                            strokeWidth={'1.5px'}
                                            _groupHover={{stroke: '#1f4d7f'}}
                                            transition={'ease-out 250ms'}/>
                                    }
                                    aria-label={'Вернуться к списку уведомлений'}
                                    size={'xs'}
                                    _active={{transform: 'scale(1.1)'}}
                                    bg={'unset'}/>
                            </Tooltip>
                            <Heading color={'#1f4d7f'} fontSize={'xl'} fontWeight={'normal'} userSelect={'none'}>Аккаунты</Heading>
                        </HStack>
                        <Tooltip hasArrow label={'Добавить аккаунт'} placement={'bottom'}>
                            <IconButton
                                onClick={() => modalEditController.onOpen({account: null, mode: AccountEditorMode.Create})}
                                variant={'unstyled'}
                                role={'group'}
                                icon={
                                    <AddIcon
                                        w={'28px'} h={'28px'}
                                        stroke={'gray.200'}
                                        strokeWidth={'1.5px'}
                                        _groupHover={{stroke: '#1f4d7f'}}
                                        transition={'ease-out 250ms'}/>
                                }
                                aria-label={'Создать уведомление'}
                                size={'xs'}
                                _active={{transform: 'scale(1.1)'}}
                                bg={'unset'}/>
                        </Tooltip>
                    </HStack>
                    {isLoading
                        ? <SkeletonText skeletonHeight={'34px'} noOfLines={10}/>
                        : <Stack flex={'1 1 0'} spacing={0} divider={<Divider/>} overflowX={'hidden'} overflowY={'auto'} bg={'#fefefe'}>
                            {users?.map(user =>
                                <AccountItem
                                    key={user.id}
                                    account={user}
                                    onEdit={account => modalEditController.onOpen({account, mode: AccountEditorMode.Edit})}
                                    onGeneratePassword={account => modalGeneratePasswordController.onOpen(account)}
                                    onRemove={account => modelDeleteController.onOpen(account)}/>
                            )}
                        </Stack>
                    }
                </Stack>
            </Container>
            <>
                {modalEditController.isOpen && modalEditController.value &&
                    <AccountEditorModal
                        account={modalEditController.value.account}
                        mode={modalEditController.value.mode}
                        onCreated={(login, passwordHash) => {
                            onLoadUsersRequest()
                            console.log(login, passwordHash)
                        }}
                        onUpdated={() => onLoadUsersRequest()}
                        {...modalEditController}/>
                }
                {modalGeneratePasswordController.isOpen && modalGeneratePasswordController.value &&
                    <AccountGeneratePasswordModal
                        account={modalGeneratePasswordController.value}
                        onGenerated={() => onLoadUsersRequest()}
                        {...modalGeneratePasswordController}/>
                }
                {modelDeleteController.isOpen && modelDeleteController.value &&
                    <AccountDeleteModal
                        account={modelDeleteController.value}
                        onRemoved={() => onLoadUsersRequest()}
                        {...modelDeleteController}/>
                }
            </>
        </>
    )
}

export default AccountListFragment