import React, {useCallback, useState} from 'react'
import {Button, Heading, HStack, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Text, UseModalProps, useToast} from "@chakra-ui/react";
import {Notification} from "../entities/Notification";
import ServerService from "../services/ServerService";

interface NotificationDeleteModalProps extends UseModalProps {
    notification: Notification,
    onRemoved?: (notification: Notification) => void
}

const NotificationDeleteModal = ({notification, onRemoved, ...props}: NotificationDeleteModalProps) => {
    //initial components
    const toast = useToast()

    //initial props
    const [isLoading, setIsLoading] = useState<boolean>(false)

    //initial actions
    const onDeleteNotificationRequest = useCallback((notification: Notification) => {
        if (!notification.id)
            return

        setIsLoading(true)
        ServerService.deleteNotification(notification.id)
            .then(() => {
                toast({
                    title: 'Уведомление успешно удалено',
                    description: `Уведомление ${notification.header} удалено`,
                    status: 'success',
                    position: 'top'
                })

                onRemoved && onRemoved(notification)
                props.onClose()
            })
            .catch(error => {
                toast({
                    title: `Ошибка удаления уведомления ${notification.header}`,
                    description: error?.response?.data ?? "Незивестная ошибка",
                    status: 'error',
                    position: 'top'
                })
            })
            .finally(() => setIsLoading(false))
    }, [])

    //build view
    return (
        <Modal
            isCentered
            size={'md'}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            scrollBehavior={'inside'}
            motionPreset='scale'
            returnFocusOnClose={false}
            {...props}>
            <ModalOverlay bg={'blackAlpha.300'} backdropFilter={{lg: 'blur(10px)'}}/>
            <ModalContent bg={'#fcfcfc'} rounded={'xl'}>
                <ModalHeader>
                    <Heading color={'red.500'} fontSize={'xl'} fontWeight={'medium'} userSelect={'none'}>Удаление уведомления</Heading>
                </ModalHeader>
                <ModalBody pb={'5'}>
                    <Stack spacing={'5'}>
                        <Text>{`Вы действительно хотите удалить уведомление ?`}</Text>
                        <HStack>
                            <Button isLoading={isLoading} flex={'1'} onClick={() => onDeleteNotificationRequest(notification)} colorScheme={'red'}>Удалить</Button>
                            <Button isDisabled={isLoading} flex={'1'} onClick={props.onClose} variant={'ghost'} color={'blackAlpha.800'}>Отмена</Button>
                        </HStack>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default NotificationDeleteModal