import React, {RefObject, useCallback, useMemo, useRef} from 'react';
import {
    Button,
    ButtonProps,
    ChakraProps,
    Flex,
    FormControl,
    FormLabel,
    FormLabelProps,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    Tooltip,
    UseModalProps,
    useToast
} from "@chakra-ui/react";
import {useFormik} from "formik";
import ServerService from "../services/ServerService";
import {User} from "../entities/User";
import {shortFullName} from "../utils/environments";

const INPUT_FULL_NAME_LIMIT = 64

export enum AccountEditorMode {
    Create,
    Edit
}

export interface AccountEditorModalProps extends UseModalProps {
    account: User | null,
    mode?: AccountEditorMode,
    onCreated?: (login: string, password: string, fullName: string, department: string, role: string) => void
    onUpdated?: (id: String, header: string, description: string, expirationDate: string) => void
}

const NotificationEditorModal = ({account = null, mode = AccountEditorMode.Create, onCreated, onUpdated, ...props}: AccountEditorModalProps) => {
    //initial components
    const toast = useToast()

    //initial refs
    const initialFocusRef = useRef() as RefObject<HTMLInputElement>

    //initial props
    const accountForm = useFormik({
        initialValues: {
            fullName: mode === 1 && account?.fullName ? account.fullName : '',
            department: mode === 1 && account?.department ? account.department : 'Учебно-методическое управление',
            role: mode === 1 && account?.role ? account?.role : 'Employer'
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate: ({fullName}) => {
            const errors: Record<string, string> = {}

            if (fullName.length < 8 || fullName.length > INPUT_FULL_NAME_LIMIT)
                errors.header = `ФИО должено содержать от 8 до ${INPUT_FULL_NAME_LIMIT} символов`

            return errors
        },
        onSubmit: ({fullName, department, role}) => {
            if (mode === 0)
                onCreateUserRequest(fullName, department, role)
            else if (mode === 1 && account?.id)
                onUpdateUserRequest(account.id, fullName, department, role)
            else
                accountForm.setSubmitting(false)
        }
    })

    //initial actions)
    const onCreateUserRequest = useCallback((fullName: string, department: string, role: string) => {
        const formData = new FormData()
        formData.append('fullName', fullName)
        formData.append('department', department)
        formData.append('role', role)

        ServerService.postUser(formData)
            .then(({data}) => {
                const {login, password} = data
                toast({
                    title: `Акканут ${shortFullName(fullName)} успешно создан`,
                    description: `Логин: ${login} | Пароль: ${password}`,
                    status: 'success',
                    position: 'top',
                    isClosable: true,
                    duration: 60000
                })
                onCreated && onCreated(login, password, fullName, department, role)
                props.onClose()
            })
            .catch(error => {
                toast({
                    title: `Ошибка создания акканута ${shortFullName(fullName)}`,
                    description: error?.response?.data ?? 'Неизвестная ошибка',
                    position: 'top',
                    status: 'error'
                })
            })
            .finally(() => accountForm.setSubmitting(false))
    }, [])
    const onUpdateUserRequest = useCallback((id: string, fullName: string, department: string, role: string) => {
        const formData = new FormData()
        formData.append('fullName', fullName)
        formData.append('department', department)
        formData.append('role', role)

        ServerService.patchUser(id, formData)
            .then(() => {
                toast({
                    title: `Акканут ${shortFullName(fullName)} успешно обновлен`,
                    status: 'success',
                    position: 'top'
                })
                onUpdated && onUpdated(id, fullName, department, role)
                props.onClose()
            })
            .catch(error => {
                toast({
                    title: `Ошибка обновления акканута ${shortFullName(fullName)}`,
                    description: error?.response?.data ?? 'Неизвестная ошибка',
                    position: 'top',
                    status: 'error'
                })
            })
            .finally(() => accountForm.setSubmitting(false))
    }, [])

    //initial props
    const labelStyleProps: FormLabelProps = useMemo(() => ({
        marginStart: '1',
        color: 'gray.400',
        fontSize: 'md',
        fontWeight: 'normal'
    }), [])
    const inputStyleProps: ChakraProps = useMemo(() => ({
        padding: '3',
        color: '#1f4d7f',
        border: '2px',
        borderColor: 'transparent',
        rounded: 'lg',
        background: 'white',
        shadow: 'base',
        _placeholder: {color: 'gray.300'},
        _focus: {borderColor: '#1f4d7f'},
        _active: {borderColor: '#1f4d7f'}
    }), [])
    const submitButtonStyleProps: ButtonProps = useMemo(() => ({
        width: {base: 'auto', lg: '210px'},
        height: '42px',
        color: 'white',
        rounded: 'lg',
        background: '#1f4d7f',
        _hover: {background: '#1a426e'},
        _active: {},
        shadow: 'base',
    }), [])

    //build view
    return (
        <Modal
            isCentered
            size={{base: 'full', lg: '3xl'}}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            scrollBehavior={'inside'}
            motionPreset='slideInRight'
            initialFocusRef={initialFocusRef}
            returnFocusOnClose={false}
            {...props}>
            <ModalOverlay bg={'blackAlpha.300'} backdropFilter={{lg: 'blur(10px)'}}/>
            <ModalContent bg={'#fcfcfc'} rounded={'xl'}>
                <ModalHeader>
                    <Heading color={'#1f4d7f'} fontSize={'xl'} fontWeight={'normal'}
                             userSelect={'none'}>{mode === 0 ? 'Добавление аккаунта' : 'Редактирование аккаунта'}</Heading>
                </ModalHeader>
                {!accountForm.isSubmitting &&
                    <Tooltip label={'Отменить и закрыть'}>
                        <ModalCloseButton color={'gray.300'} _hover={{color: '#1f4d7f'}}/>
                    </Tooltip>
                }
                <ModalBody pb={'5'}>
                    <Flex flexDir={'column'} userSelect={'none'}>
                        <FormControl>
                            <FormLabel htmlFor={'fullName'} {...labelStyleProps}>Фамилия, Имя, Отчество</FormLabel>
                            <Tooltip hasArrow label={accountForm.errors.fullName} bg={'red.500'} placement={'top'} isOpen={!!accountForm.errors.fullName}>
                                <Input
                                    ref={initialFocusRef}
                                    id={'fullName'}
                                    placeholder={'Введите фамилию, имя, отчество'}
                                    isDisabled={accountForm.isSubmitting}
                                    {...accountForm.getFieldProps('fullName')}
                                    {...inputStyleProps}/>
                            </Tooltip>
                            <Text
                                w={'max-content'}
                                ms={'auto'} mt={'1.5'}
                                px={'3'}
                                color={accountForm.values.fullName.length > INPUT_FULL_NAME_LIMIT ? 'red.400' : 'gray.300'}
                                fontSize={'smaller'}>{`${accountForm.values.fullName.length}/${INPUT_FULL_NAME_LIMIT} сим.`}</Text>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor={'department'} {...labelStyleProps}>Отдел</FormLabel>
                            <Select
                                id={'department'}
                                isDisabled={accountForm.isSubmitting}
                                {...accountForm.getFieldProps('department')}
                                {...inputStyleProps}
                                p={'0'}>
                                <option>Учебно-методическое управление</option>
                                <option>Управление научных исследований</option>
                                <option>Управление по делам студентов</option>
                                <option>Управление по работе с персоналом и документационному обеспечению</option>
                                <option>Управление информатизации</option>
                                <option>Административно-хозяйственное управление</option>
                                <option>Управление бухгалтерского учета и финансового контроля</option>
                                <option>Управление нормативно-правового и финансово-экономического обеспечения</option>
                                <option>Отдел по работе с абитуриентами</option>
                                <option>Отдел по связям с общественностью</option>
                                <option>Отдел управления качеством</option>
                                <option>Отдел международного сотрудничества</option>
                                <option>Центр культуры и творчества</option>
                                <option>Центр развития карьеры</option>
                                <option>Учебный офис образовательных программ</option>
                                <option>Управление лицензирования и аккредитации</option>
                                <option>Первый проректор, проректор по научной работе</option>
                                <option>Проректор по образовательной деятельности</option>
                                <option>Управление по делам студентов</option>
                            </Select>
                        </FormControl>
                        <FormControl mt={'3'}>
                            <FormLabel htmlFor={'role'} {...labelStyleProps}>Роль</FormLabel>
                            <Select
                                id={'role'}
                                isDisabled={accountForm.isSubmitting}
                                {...accountForm.getFieldProps('role')}
                                {...inputStyleProps}
                                p={'0'}>
                                <option value={'Admin'}>Администратор</option>
                                <option value={'Employer'}>Сотрудник</option>
                            </Select>
                        </FormControl>
                        <Button
                            isLoading={accountForm.isSubmitting}
                            onClick={accountForm.submitForm}
                            mt={'6'} ms={'auto'}
                            {...submitButtonStyleProps}>{mode === 0 ? 'Добавить аккаунт' : 'Редактировать аккаунт'}</Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default NotificationEditorModal