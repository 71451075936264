import {useCallback, useState} from "react";

interface UseControllerProps<T> {
    value: T | null,
    isOpen: boolean,
    onOpen: (data?: T | null) => void,
    onClose: () => void
}

const useController = <T>(initialValue?: T): UseControllerProps<T> => {
    //initial states
    const [value, setValue] = useState<T | null>(initialValue ?? null)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    //initial actions
    const onOpen = useCallback((data?: T | null) => {
        setValue(data ?? null)
        setIsOpen(true)
    }, [])
    const onClose = useCallback(() => {
        setValue(null)
        setIsOpen(false)
    }, [])

    //return controller object
    return {value, isOpen, onOpen, onClose}
}

export default useController