import React from 'react';
import {Icon, IconProps} from "@chakra-ui/react";

const KeyIcon = (props: IconProps) => (
    <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M14 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"/>
        <path d="M21 12a9 9 0 1 1 -18 0a9 9 0 0 1 18 0z"/>
        <path d="M12.5 11.5l-4 4l1.5 1.5"/>
        <path d="M12 15l-1.5 -1.5"/>
    </Icon>
)

export default KeyIcon;