import React, {useCallback, useContext, useEffect, useRef} from 'react'
import {Box, Button, ChakraProps, HStack, IconButton, Image, Stack, Text, Tooltip} from "@chakra-ui/react"
import UserSessionContext from "./context/UserSessionContext";
import LogoutIcon from "./components/icons/LogoutIcon";
import UserSessionControlContext from "./context/UserSessionControlContext";

const AppHeader = (props: ChakraProps) => {
    //initial contexts
    const {fullName, department} = useContext(UserSessionContext) ?? {}
    const userSessionControl = useContext(UserSessionControlContext)

    //build view
    return (
        <Box {...props}>
            <HStack justifyContent={'space-between'}>
                <Image
                    width={{base: '155px', lg: '210px'}} height={'100%'}
                    src='https://nvsu.ru/files/site/theme/logo1.png?1'
                    alt='nvsu logo'/>
                {fullName && department &&
                    <HStack spacing={4}>
                        <Stack spacing={'-0.5'} alignItems={'end'} textAlign={'end'}>
                            <Text color={'gray.700'} fontSize={'md'} fontWeight={'medium'}>{fullName}</Text>
                            <Text color={'gray.500'} fontSize={'sm'} fontWeight={'thin'}>{department}</Text>
                        </Stack>
                        <Tooltip hasArrow label={'Выйти из аккаунта'} placement={'bottom-start'}>
                            <IconButton
                                onClick={userSessionControl?.onClearSession}
                                role={'group'}
                                icon={
                                    <LogoutIcon
                                        stroke={'gray.300'}
                                        strokeWidth={'1.5px'}
                                        _groupHover={{stroke: 'gray.700'}}
                                        transition={'ease 250ms'}/>
                                }
                                aria-label={'Выйти из аккаунта'}
                                transition={'ease 250ms'}/>
                        </Tooltip>
                    </HStack>
                }
            </HStack>
        </Box>
    )
}

export default AppHeader