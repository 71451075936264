import axios, {AxiosRequestConfig} from "axios";

export default class ServerService {
    static server = axios.create({baseURL: `/api/`})
    static api = axios.create({baseURL: `https://api.nvsu.ru/`})

    //login
    static login = (login: string, passwordHash: string, config?: AxiosRequestConfig<any>) => {
        const formData = new FormData()
        formData.append('login', login)
        formData.append('passwordHash', passwordHash)

        return this.server.post(`login`, formData, config)
    }
    static unLogin = (config?: AxiosRequestConfig<any>) => this.server.post(`unlogin`, config)
    static session = (config?: AxiosRequestConfig<any>) => this.server.get(`session`, config)

    //notifications
    static getNotifications = (config?: AxiosRequestConfig<any>) => this.server.get(`notifications`, config)
    static postNotification = (data: any, config?: AxiosRequestConfig<any>) => this.server.post(`notifications`, data, config)
    static patchNotification = (id: string, data: any, config?: AxiosRequestConfig<any>) => this.server.patch(`notifications/${id}`, data, config)
    static deleteNotification = (id: string, config?: AxiosRequestConfig<any>) => this.server.delete(`notifications/${id}`, config)

    //users
    static getUsers = (config?: AxiosRequestConfig<any>) => this.server.get(`users`, config)
    static postUser = (data: any, config?: AxiosRequestConfig<any>) => this.server.post(`users`, data, config)
    static patchUser = (id: string, data: any, config?: AxiosRequestConfig<any>) => this.server.patch(`users/${id}`, data, config)
    static deleteUser = (id: string, config?: AxiosRequestConfig<any>) => this.server.delete(`users/${id}`, config)

    //actions
    static generatePassword = (id: String, config?: AxiosRequestConfig<any>) => this.server.post(`users/${id}/generateNewPassword`, undefined, config)
}