import React from 'react';
import {Box, Button, ChakraProps, Flex, IconButton} from "@chakra-ui/react";
import {ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {Pagination} from "../utils/pagination";

const buttonStyle = {
    size: 'sm',
    color: 'gray.300',
    background: 'transparent',
    borderRadius: '0',
    _hover: {
        color: 'gray.400'
    },
    _active: {
        color: '#1f4d7f'
    }
}

export interface PaginationBarProps extends ChakraProps {
    pagination: Pagination
    onPageSelected?: (page: number) => void
}

export const PaginationBar = ({pagination, onPageSelected, ...props}: PaginationBarProps) => {
    const {page, totalPage} = pagination

    const _page = page < 1 || page > totalPage ? 1 : page
    const _total = totalPage < page ? 1 : totalPage

    let leftCount = page - 2
    let rightCount = page + 2

    if (leftCount <= 1) {
        rightCount -= leftCount - 1
        leftCount = 1
    }

    if (rightCount > _total) {
        leftCount -= rightCount - _total
        rightCount = _total
    }

    const paginateButtons = []
    for (let pageNumber = leftCount; pageNumber <= rightCount; pageNumber++) {
        if (pageNumber > 0) {
            const isSelected = +_page === pageNumber

            paginateButtons.push(
                <Button
                    {...buttonStyle}
                    key={pageNumber}
                    isActive={isSelected}
                    onClick={() => onPageSelected && onPageSelected(pageNumber)}>
                    {pageNumber}
                </Button>
            )
        }
    }

    return (
        <Box
            w={'max-content'}
            ms={'auto'}
            me={'1'}
            background={'white'}
            rounded={'lg'}
            shadow={'base'}
            {...props}>
            <Flex>
                <IconButton
                    {...buttonStyle}
                    icon={<ArrowLeftIcon boxSize={3}/>}
                    aria-label="Первая страница"
                    isDisabled={_page <= 1}
                    onClick={() => onPageSelected && onPageSelected(1)}
                />
                <IconButton
                    {...buttonStyle}
                    icon={<ChevronLeftIcon boxSize={5}/>}
                    aria-label="Предыдущая страница"
                    isDisabled={_page <= 1}
                    onClick={() => {
                        const calcPage = _page - 1
                        onPageSelected && onPageSelected(calcPage < 1 ? 1 : calcPage)
                    }}
                />
                {paginateButtons}
                <IconButton
                    {...buttonStyle}
                    icon={<ChevronRightIcon boxSize={5}/>}
                    aria-label="Следующая страница"
                    isDisabled={_page >= _total}
                    onClick={() => {
                        const calcPage = page + 1
                        onPageSelected && onPageSelected(calcPage > totalPage ? totalPage : calcPage)
                    }}
                />
                <IconButton
                    {...buttonStyle}
                    icon={<ArrowRightIcon boxSize={3}/>}
                    aria-label="Последняя страница"
                    isDisabled={_page >= _total}
                    onClick={() => onPageSelected && onPageSelected(totalPage)}
                />
            </Flex>
        </Box>
    )
}