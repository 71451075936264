import React, {useEffect} from 'react'
import {ChakraProvider} from "@chakra-ui/react";
import AppScreen from "./AppScreen";
import {BrowserRouter} from "react-router-dom";
import moment from "moment-ru";

export default function App() {
    //initial effects
    useEffect(() => {
        moment.locale('ru')
    }, [])

    //build view
    return (
        <ChakraProvider>
            <BrowserRouter>
                <AppScreen/>
            </BrowserRouter>
        </ChakraProvider>
    )
}