import React, {useCallback, useState} from 'react'
import {Button, Heading, HStack, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Text, UseModalProps, useToast} from "@chakra-ui/react";
import ServerService from "../services/ServerService";
import {User} from "../entities/User";
import {shortFullName} from "../utils/environments";

interface AccountGeneratePasswordModalProps extends UseModalProps {
    account: User,
    onGenerated?: (password: string) => void
}

const AccountGeneratePasswordModal = ({account, onGenerated, ...props}: AccountGeneratePasswordModalProps) => {
    //initial components
    const toast = useToast()

    //initial props
    const [isLoading, setIsLoading] = useState<boolean>(false)

    //initial actions
    const onGeneratePasswordRequest = useCallback((account: User) => {
        if (!account.id)
            return

        setIsLoading(true)
        ServerService.generatePassword(account.id)
            .then(({data}) => {
                const {password} = data
                toast({
                    title: `Новый пароль успешно сгенерирован для пользователя ${shortFullName(account.fullName)}`,
                    description: `Новый пароль: ${password}`,
                    status: 'success',
                    position: 'top',
                    isClosable: true,
                    duration: 60000
                })

                onGenerated && onGenerated(password)
                props.onClose()
            })
            .catch(error => {
                toast({
                    title: `Ошибка генерации нового пароля для пользователя ${shortFullName(account.fullName)}`,
                    description: error?.response?.data ?? "Незивестная ошибка",
                    status: 'error',
                    position: 'top'
                })
            })
            .finally(() => setIsLoading(false))
    }, [])

    //build view
    return (
        <Modal
            isCentered
            size={'md'}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            scrollBehavior={'inside'}
            motionPreset='scale'
            returnFocusOnClose={false}
            {...props}>
            <ModalOverlay bg={'blackAlpha.300'} backdropFilter={{lg: 'blur(10px)'}}/>
            <ModalContent bg={'#fcfcfc'} rounded={'xl'}>
                <ModalHeader>
                    <Heading color={'orange.500'} fontSize={'xl'} fontWeight={'medium'} userSelect={'none'}>Сгенерировать новый пароль</Heading>
                </ModalHeader>
                <ModalBody pb={'5'}>
                    <Stack spacing={'5'}>
                        <Text>{`Вы действительно хотите сгенерировать новый пароль для пользователя ${account.fullName} ?`}</Text>
                        <HStack>
                            <Button isLoading={isLoading} flex={'1'} onClick={() => onGeneratePasswordRequest(account)} colorScheme={'orange'}>Сгенерировать</Button>
                            <Button isDisabled={isLoading} flex={'1'} onClick={props.onClose} variant={'ghost'} color={'blackAlpha.800'}>Отмена</Button>
                        </HStack>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default AccountGeneratePasswordModal