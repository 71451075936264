import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
    ChakraProps,
    Container,
    Divider,
    Flex,
    Heading,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    ScaleFade,
    SkeletonText,
    Spinner,
    Stack,
    Text,
    Tooltip,
    useToast
} from "@chakra-ui/react";
import AddIcon from "../components/icons/AddIcon";
import SearchIcon from "../components/icons/SearchIcon";
import NotificationEditorModal, {NotificationEditorMode} from "../components/NotificationEditorModal";
import {Notification} from "../entities/Notification";
import ServerService from "../services/ServerService";
import NotificationDeleteModal from "../components/NotificationDeleteModal";
import useController from "../hooks/ControllerHook";
import UserSessionContext from "../context/UserSessionContext";
import NotificationItem from "../components/NotificationItem";
import {collectPagination, Pagination} from "../utils/pagination";
import {AxiosResponseHeaders} from "axios";
import {PaginationBar} from "../components/PaginationBar";
import {SmallCloseIcon} from "@chakra-ui/icons";
import UsersIcon from "../components/icons/UsersIcon";
import {useNavigate} from "react-router-dom";

interface NotificationWithEditorMode {
    notification: Notification | null,
    mode?: NotificationEditorMode
}

const NotificationListFragment = (props: ChakraProps) => {
    //initial contexts
    const user = useContext(UserSessionContext)

    //initial refs
    const abortControllerRef = useRef<AbortController>(new AbortController())
    const searchQueryTimeoutIdRef = useRef<any>()

    //initial components
    const toast = useToast()
    const navigate = useNavigate()

    //initial states
    const [notifications, setNotifications] = useState<Array<Notification>>([])
    const [pagination, setPagination] = useState<Pagination | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const modalEditController = useController<NotificationWithEditorMode>()
    const modelDeleteController = useController<Notification>()

    //initial actions
    const onSearchQueryInput = useCallback((query: string) => {
        setSearchQuery(query)

        abortControllerRef.current?.abort()
        clearTimeout(searchQueryTimeoutIdRef.current)

        searchQueryTimeoutIdRef.current = setTimeout(() => {
            abortControllerRef.current = new AbortController()
            setPage(1)

            onLoadNotificationRequest(query, 1)
        }, query.trim() === '' ? 250 : 1250)
    }, [])
    const onLoadNotificationRequest = useCallback((_searchQuery?: string, _page?: number) => {
        setIsLoading(true)
        setNotifications([])

        ServerService.getNotifications({
            signal: abortControllerRef.current?.signal,
            params: {
                _page: _page ?? page,
                _limit: 10,
                _all: true,
                '_search:header:description': _searchQuery?.trim() ?? searchQuery.trim()
            }
        })
            .then(({data, headers}) => {
                setNotifications(data)
                setPagination(collectPagination(headers as AxiosResponseHeaders))
            })
            .catch(() => {
                if (abortControllerRef.current?.signal?.aborted)
                    return

                toast({
                    title: 'Ошибка загрузки списка уведомлений',
                    description: 'Проверьте подключение к сети',
                    status: 'error',
                    position: 'top'
                })
            })
            .finally(() => setIsLoading(false))
    }, [page, searchQuery])

    //initial effects
    useEffect(() => {
        onLoadNotificationRequest()
    }, [])

    //build view
    return (
        <>
            <Container maxW={'container.xl'} p={'0'} {...props}>
                <Stack spacing={0} minH={'100%'} overflow={'hidden'} px={{base: '4', lg: '6'}} bg={'white'} borderRadius={'lg'} shadow={'base'}>
                    <HStack spacing={'3'} zIndex={'100'} minH={'64px'} justifyContent={'space-between'}>
                        <Heading color={'#1f4d7f'} fontSize={'xl'} fontWeight={'normal'} userSelect={'none'}>Уведомления</Heading>
                        <InputGroup maxW={'440px'}>
                            <InputLeftElement h={'100%'}>
                                {isLoading
                                    ? <Spinner size={'sm'} color={'gray.300'}/>
                                    : <SearchIcon
                                        w={'20px'} h={'20px'}
                                        stroke={'gray.300'}
                                        strokeWidth={'1.25px'}/>
                                }
                            </InputLeftElement>
                            <Input
                                value={searchQuery}
                                onInput={(event: React.ChangeEvent<HTMLInputElement>) => onSearchQueryInput(event.target.value)}
                                variant={'unstyled'}
                                height={'35px'}
                                placeholder={isLoading ? 'Загрузка..' : 'Поиск..'}
                                color={'gray.400'}
                                bg={'#fcfcfc'}
                                shadow={'inner'}
                                borderRadius={'3xl'}
                                _placeholder={{color: 'gray.300'}}
                                _focus={{borderColor: '#1f4d7f'}}/>
                            <InputRightElement h={'100%'}>
                                <ScaleFade initialScale={0.9} in={searchQuery.length > 0}>
                                    <IconButton
                                        size={'xs'}
                                        icon={<SmallCloseIcon color={'gray.300'}/>}
                                        aria-label={'Очистить поле поиска'}
                                        onClick={() => onSearchQueryInput('')}
                                        bg={'white'}
                                        rounded={'lg'}
                                        shadow={'base'}
                                        _hover={{bg: 'gray.50'}}
                                        _active={{bg: 'gray.100'}}/>
                                </ScaleFade>
                            </InputRightElement>
                        </InputGroup>
                        <HStack spacing={'5'}>
                            {user?.role === 'Admin' &&
                                <Tooltip hasArrow label={'Пользователи'} placement={'bottom'}>
                                    <IconButton
                                        onClick={() => navigate('/accounts')}
                                        variant={'unstyled'}
                                        role={'group'}
                                        icon={
                                            <UsersIcon
                                                w={'28px'} h={'28px'}
                                                stroke={'gray.200'}
                                                strokeWidth={'1.5px'}
                                                _groupHover={{stroke: '#1f4d7f'}}
                                                transition={'ease-out 250ms'}/>
                                        }
                                        aria-label={'Пользователи'}
                                        size={'xs'}
                                        _active={{transform: 'scale(1.1)'}}
                                        bg={'unset'}/>
                                </Tooltip>
                            }
                            <Tooltip hasArrow label={'Создать уведомление'} placement={'bottom'}>
                                <IconButton
                                    onClick={() => modalEditController.onOpen({notification: null, mode: NotificationEditorMode.Create})}
                                    variant={'unstyled'}
                                    role={'group'}
                                    icon={
                                        <AddIcon
                                            w={'28px'} h={'28px'}
                                            stroke={'gray.200'}
                                            strokeWidth={'1.5px'}
                                            _groupHover={{stroke: '#1f4d7f'}}
                                            transition={'ease-out 250ms'}/>
                                    }
                                    aria-label={'Создать уведомление'}
                                    size={'xs'}
                                    _active={{transform: 'scale(1.1)'}}
                                    bg={'unset'}/>
                            </Tooltip>
                        </HStack>
                    </HStack>
                    {isLoading
                        ? <SkeletonText skeletonHeight={'34px'} noOfLines={10}/>
                        : <Flex flex={'1 1 0'} flexDir={'column'} overflow={'hidden'} bg={'#fefefe'}>
                            <Stack flex={'1 1 0'} spacing={0} divider={<Divider/>} overflowX={'hidden'} overflowY={'auto'}>
                                {notifications?.length > 0
                                    ? (notifications?.map(notification =>
                                        <NotificationItem
                                            key={notification.id}
                                            notification={notification}
                                            enableEditButton={notification.author.id === user?._id}
                                            enableDeleteButton={notification.author.id === user?._id}
                                            onEdit={(notification: Notification) => modalEditController.onOpen({notification, mode: NotificationEditorMode.Edit})}
                                            onRemove={(notification: Notification) => modelDeleteController.onOpen(notification)}/>
                                    ))
                                    : <Text color={'gray.300'} fontSize={'lg'} m={'auto'} textTransform={'uppercase'}>Уведомления отсутствуют</Text>
                                }
                            </Stack>
                            {pagination && pagination?.totalPage > 1 &&
                                <PaginationBar
                                    pagination={pagination}
                                    onPageSelected={page => {
                                        setPage(page)
                                        onLoadNotificationRequest(undefined, page)
                                    }}
                                    my={'3'}/>
                            }
                        </Flex>
                    }
                </Stack>
            </Container>
            <>
                {modalEditController.isOpen && modalEditController.value &&
                    <NotificationEditorModal
                        notification={modalEditController.value.notification}
                        mode={modalEditController.value.mode}
                        onCreated={() => onLoadNotificationRequest()}
                        onUpdated={() => onLoadNotificationRequest()}
                        {...modalEditController}/>
                }
                {modelDeleteController.isOpen && modelDeleteController.value &&
                    <NotificationDeleteModal
                        notification={modelDeleteController.value}
                        onRemoved={() => onLoadNotificationRequest()}
                        {...modelDeleteController}/>
                }
            </>
        </>
    )
}

export default NotificationListFragment