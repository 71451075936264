import React, {useCallback, useContext} from 'react';
import {
    Button,
    ButtonProps,
    ChakraProps,
    Container,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputProps,
    Stack,
    TagLabelProps,
    Text,
    TextProps,
    Tooltip,
    useToast
} from "@chakra-ui/react";
import {useFormik} from "formik";
import MailIcon from "../components/icons/MailIcon";
import ServerService from "../services/ServerService";
import {sha1} from "../utils/sha1";
import userSessionControlContext from "../context/UserSessionControlContext";

const styles = {
    heading: {
        color: '#1f4d7f',
        fontSize: '4xl',
        fontWeight: 'thin',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    label: {
        color: '#72808F',
        fontSize: 'sm',
        fontWeight: 'normal',
        marginLeft: '2',
        marginBottom: '1',
    },
    input: {
        height: '45px',
        paddingX: '3',
        color: 'blackAlpha.700',
        background: 'white',
        border: '1px',
        borderColor: 'gray.200',
        errorBorderColor: 'red.500',
        _focus: {
            borderColor: '#1f4d7f',
            transform: 'scale(1.0125)',
        }

    },
    submitButton: {
        color: 'white',
        fontSize: 'xs',
        textTransform: 'uppercase',
        background: '#1f4d7f',
        height: '45px',
        marginTop: '3',
        _hover: {
            background: '#255d98'
        }
    }
}

const LoginFragment = (props: ChakraProps) => {
    //initial components
    const toast = useToast()

    //initial contexts
    const userSessionControl = useContext(userSessionControlContext)

    //initial props
    const loginForm = useFormik({
        initialValues: {
            login: '',
            password: ''
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate: ({login, password}) => {
            const errors: Record<string, string> = {}

            if (login === '')
                errors.login = "Не может быть пустым"

            if (password === '')
                errors.password = "Не может быть пустым"

            return errors
        },
        onSubmit: ({login, password}) => onLogin(login, password)
    })

    //initial actions
    const onLogin = useCallback((login: string, password: string) => {
        loginForm.setSubmitting(true)
        ServerService.login(login, sha1(password))
            .then(response => {
                toast({
                    title: `Вход выполнен`,
                    description: response.data,
                    status: 'success',
                    position: 'top'
                })
                userSessionControl?.onLoadSession()
            })
            .catch(error => {
                const {data, status} = error.response
                toast({
                    title: `Ошибка авторизации - ${status}`,
                    description: data,
                    status: 'error',
                    position: 'top'
                })
            })
            .finally(() => loginForm.setSubmitting(false))
    }, [])

    //build view
    return (
        <Container maxW={'440px'} {...props}>
            <Flex flexDir={'column'} rowGap={'3'}>
                <Stack spacing={'0'} mx={'auto'} my={'3'}>
                    <Text {...styles.heading as TextProps}>Сервис</Text>
                    <Text {...styles.heading as TextProps} fontWeight={'medium'}>Уведомлений</Text>
                </Stack>
                <HStack>
                    <Divider/>
                    <MailIcon width={'64px'} height={'64px'} stroke={'#1f4d7f'} strokeWidth={'0.5'}/>
                    <Divider/>
                </HStack>
                <FormControl isDisabled={loginForm.isSubmitting}>
                    <FormLabel htmlFor={'login'} {...styles.label as TagLabelProps}>Учетная запись</FormLabel>
                    <Tooltip isOpen={!!loginForm.errors.login} hasArrow placement={'top'} label={'Заполните поле'} bg={'red.500'}>
                        <Input
                            id={'login'}
                            isInvalid={!!loginForm.errors.login}
                            placeholder={'Введите логин'}
                            {...loginForm.getFieldProps('login')}
                            {...styles.input as InputProps}/>
                    </Tooltip>
                </FormControl>
                <FormControl isDisabled={loginForm.isSubmitting}>
                    <FormLabel htmlFor={'password'} {...styles.label as TagLabelProps}>Пароль</FormLabel>
                    <Tooltip isOpen={!!loginForm.errors.password} hasArrow placement={'top'} label={'Заполните поле'} bg={'red.500'}>
                        <Input
                            id={'password'}
                            isInvalid={!!loginForm.errors.password}
                            type={'password'}
                            placeholder={'Введите пароль'}
                            {...loginForm.getFieldProps('password')}
                            {...styles.input as InputProps}/>
                    </Tooltip>
                </FormControl>
                <Button
                    type={'submit'}
                    isLoading={loginForm.isSubmitting}
                    loadingText={'Авторизация'}
                    onClick={() => loginForm.submitForm()}
                    {...styles.submitButton as ButtonProps}>Войти</Button>
            </Flex>
        </Container>
    )
};

export default LoginFragment;