import React, {RefObject, useCallback, useMemo, useRef} from 'react';
import {
    Button,
    ButtonProps,
    ChakraProps,
    Flex,
    FormControl,
    FormLabel,
    FormLabelProps,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    TextProps,
    Tooltip,
    UseModalProps,
    useToast
} from "@chakra-ui/react";
import {useFormik} from "formik";
import moment from "moment-ru";
import ServerService from "../services/ServerService";
import {Notification} from "../entities/Notification";

const INPUT_HEADER_LIMIT = 128
const INPUT_DESCRIPTION_LIMIT = 1024

export enum NotificationEditorMode {
    Create,
    Edit
}

export interface NotificationEditorModalProps extends UseModalProps {
    notification: Notification | null,
    mode?: NotificationEditorMode,
    onCreated?: (header: string, description: string, expirationDate: string) => void
    onUpdated?: (id: String, header: string, description: string, expirationDate: string) => void
}

const NotificationEditorModal = ({notification = null, mode = NotificationEditorMode.Create, onCreated, onUpdated, ...props}: NotificationEditorModalProps) => {
    //initial components
    const toast = useToast()

    //initial refs
    const initialFocusRef = useRef() as RefObject<HTMLInputElement>

    //initial props
    const notificationForm = useFormik({
        initialValues: {
            header: mode === 1 && notification?.header ? notification.header : '',
            description: mode === 1 && notification?.description ? notification.description : '',
            expirationDate: mode === 1 && notification?.expirationDate ? notification?.expirationDate : moment().add(1, 'day').format('YYYY-MM-DD')
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate: ({header, description, expirationDate}) => {
            const errors: Record<string, string> = {}
            const now = moment()

            if (header.length < 8 || header.length > INPUT_HEADER_LIMIT)
                errors.header = `Заголовок должен содержать от 8 до ${INPUT_HEADER_LIMIT} символов`
            if (description.length < 16 || description.length > INPUT_DESCRIPTION_LIMIT)
                errors.description = `Описание должно содержать от 16 до ${INPUT_DESCRIPTION_LIMIT} символов`
            if (expirationDate === '' || !moment(expirationDate).isAfter(now))
                errors.expirationDate = 'Дата окончания не может быть раньше или равной сегодняшней'

            return errors
        },
        onSubmit: ({header, description, expirationDate}) => {
            if (mode === 0)
                onCreateNotificationRequest(header, description, expirationDate)
            else if (mode === 1 && notification?.id)
                onUpdateNotificationRequest(notification.id, header, description, expirationDate)
            else
                notificationForm.setSubmitting(false)
        }
    })

    //initial actions
    const onChangeExpirationDate = useCallback((unit: moment.unitOfTime.DurationConstructor) => (
        notificationForm.setFieldValue('expirationDate', moment().add(1, unit).format('YYYY-MM-DD'))
    ), [])
    const onCreateNotificationRequest = useCallback((header: string, description: string, expirationDate: string) => {
        const formData = new FormData()
        formData.append('header', header)
        formData.append('description', description)
        formData.append('expirationDate', expirationDate)

        ServerService.postNotification(formData)
            .then(() => {
                toast({
                    title: 'Уведомление успешно создано',
                    status: 'success',
                    position: 'top'
                })
                onCreated && onCreated(header, description, expirationDate)
                props.onClose()
            })
            .catch(error => {
                toast({
                    title: 'Ошибка создания уведомления',
                    description: error?.response?.data ?? 'Неизвестная ошибка',
                    position: 'top',
                    status: 'error'
                })
            })
            .finally(() => notificationForm.setSubmitting(false))
    }, [])
    const onUpdateNotificationRequest = useCallback((id: string, header: string, description: string, expirationDate: string) => {
        const formData = new FormData()
        formData.append('header', header)
        formData.append('description', description)
        formData.append('expirationDate', expirationDate)

        ServerService.patchNotification(id, formData)
            .then(() => {
                toast({
                    title: 'Уведомление успешно обновлено',
                    status: 'success',
                    position: 'top'
                })
                onUpdated && onUpdated(id, header, description, expirationDate)
                props.onClose()
            })
            .catch(error => {
                toast({
                    title: 'Ошибка обновления уведомления',
                    description: error?.response?.data ?? 'Неизвестная ошибка',
                    position: 'top',
                    status: 'error'
                })
            })
            .finally(() => notificationForm.setSubmitting(false))
    }, [])

    //initial props
    const labelStyleProps: FormLabelProps = useMemo(() => ({
        marginStart: '1',
        color: 'gray.400',
        fontSize: 'md',
        fontWeight: 'normal'
    }), [])
    const inputStyleProps: ChakraProps = useMemo(() => ({
        variant: 'unstyled',
        padding: '3',
        color: '#1f4d7f',
        border: '2px',
        borderColor: 'transparent',
        rounded: 'lg',
        background: 'white',
        shadow: 'base',
        _placeholder: {color: 'gray.300'},
        _focus: {borderColor: '#1f4d7f'},
        _active: {borderColor: '#1f4d7f'}
    }), [])
    const submitButtonStyleProps: ButtonProps = useMemo(() => ({
        width: {base: 'auto', lg: '210px'},
        height: '42px',
        color: 'white',
        rounded: 'lg',
        background: '#1f4d7f',
        _hover: {background: '#1a426e'},
        _active: {},
        shadow: 'base',
    }), [])
    const dateButtonProps: ButtonProps = useMemo(() => ({
        height: '40px',
        paddingX: '5',
        paddingY: '2',
        color: 'gray.300',
        background: 'rgba(31,77,127,0.05)',
        rounded: 'md',
        shadow: 'xs',
        _hover: {bg: 'rgba(31,77,127,0.075)'},
        _active: {
            color: 'rgba(31,77,127,1)',
            bg: 'rgba(31,77,127,0.05)',
        }
    }), [])
    const isDateBetweenFrom = useCallback((iso: string, unit: moment.unitOfTime.DurationConstructor) => {
        const now = moment()
        const period = now.add(1, unit)
        return moment(iso).isBetween(now, period, 'date', '[]')
    }, [])

    //build view
    return (
        <Modal
            isCentered
            size={{base: 'full', lg: '6xl'}}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            scrollBehavior={'inside'}
            motionPreset='slideInRight'
            initialFocusRef={initialFocusRef}
            returnFocusOnClose={false}
            {...props}>
            <ModalOverlay bg={'blackAlpha.300'} backdropFilter={{lg: 'blur(10px)'}}/>
            <ModalContent bg={'#fcfcfc'} rounded={'xl'}>
                <ModalHeader>
                    <Heading color={'#1f4d7f'} fontSize={'xl'} fontWeight={'normal'}
                             userSelect={'none'}>{mode === 0 ? 'Создание уведомления' : 'Редактирование уведомления'}</Heading>
                </ModalHeader>
                {!notificationForm.isSubmitting &&
                    <Tooltip label={'Отменить и закрыть'}>
                        <ModalCloseButton color={'gray.300'} _hover={{color: '#1f4d7f'}}/>
                    </Tooltip>
                }
                <ModalBody pb={'5'}>
                    <Flex flexDir={'column'} userSelect={'none'}>
                        {/*<FormControl>*/}
                        {/*    <FormLabel {...labelStyleProps}>Фильтры</FormLabel>*/}
                        {/*    <NotificationFilterEditor/>*/}
                        {/*</FormControl>*/}
                        <FormControl>
                            <FormLabel htmlFor={'header'} {...labelStyleProps}>Заголовок</FormLabel>
                            <Tooltip hasArrow label={notificationForm.errors.header} bg={'red.500'} placement={'top'} isOpen={!!notificationForm.errors.header}>
                                <Input
                                    ref={initialFocusRef}
                                    id={'header'}
                                    placeholder={'Введите заголовок уведомления'}
                                    isDisabled={notificationForm.isSubmitting}
                                    {...notificationForm.getFieldProps('header')}
                                    {...inputStyleProps}/>
                            </Tooltip>
                            <Text
                                w={'max-content'}
                                ms={'auto'} mt={'1.5'}
                                px={'3'}
                                color={notificationForm.values.header.length > INPUT_HEADER_LIMIT ? 'red.400' : 'gray.300'}
                                fontSize={'smaller'}>{`${notificationForm.values.header.length}/${INPUT_HEADER_LIMIT} сим.`}</Text>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor={'description'} {...labelStyleProps}>Описание</FormLabel>
                            <Tooltip hasArrow label={notificationForm.errors.description} bg={'red.500'} placement={'top'} isOpen={!!notificationForm.errors.description}>
                                <Textarea
                                    id={'description'}
                                    placeholder={'Введите описание уведомления'}
                                    isDisabled={notificationForm.isSubmitting}
                                    minH={'242px'}
                                    resize={'none'}
                                    {...notificationForm.getFieldProps('description')}
                                    {...inputStyleProps}/>
                            </Tooltip>
                            <Text
                                w={'max-content'}
                                ms={'auto'} mt={'1.5'}
                                px={'3'}
                                color={notificationForm.values.description.length > INPUT_DESCRIPTION_LIMIT ? 'red.400' : 'gray.300'}
                                fontSize={'smaller'}>{`${notificationForm.values.description.length}/${INPUT_DESCRIPTION_LIMIT} сим.`}</Text>
                        </FormControl>
                        <Flex mt={'3'} flexDir={{base: 'column', lg: 'row'}} gap={'1'} alignItems={'stretch'} justifyContent={'space-between'}>
                            <Flex flex={'1'} gap={'2'} alignItems={'center'}>
                                <Tooltip hasArrow label={'На один день'}>
                                    <Button
                                        isActive={isDateBetweenFrom(notificationForm.values.expirationDate, 'day')}
                                        onClick={() => onChangeExpirationDate('day')}
                                        isDisabled={notificationForm.isSubmitting}
                                        {...dateButtonProps}>День</Button>
                                </Tooltip>
                                <Tooltip hasArrow label={'На одну неделю'}>
                                    <Button
                                        isActive={isDateBetweenFrom(notificationForm.values.expirationDate, 'week')}
                                        onClick={() => onChangeExpirationDate('week')}
                                        isDisabled={notificationForm.isSubmitting}
                                        {...dateButtonProps}>Неделю</Button>
                                </Tooltip>
                                <Tooltip hasArrow label={'На один месяц'}>
                                    <Button
                                        isActive={isDateBetweenFrom(notificationForm.values.expirationDate, 'month')}
                                        onClick={() => onChangeExpirationDate('month')}
                                        isDisabled={notificationForm.isSubmitting}
                                        {...dateButtonProps}>Месяц</Button>
                                </Tooltip>
                                <Tooltip hasArrow label={'На один год'}>
                                    <Button
                                        isActive={isDateBetweenFrom(notificationForm.values.expirationDate, 'year')}
                                        onClick={() => onChangeExpirationDate('year')}
                                        isDisabled={notificationForm.isSubmitting}
                                        {...dateButtonProps}>Год</Button>
                                </Tooltip>
                                <FormLabel htmlFor={'expirationDate'} ms={'auto'} my={'auto'} position={'relative'}>
                                    <Input
                                        type={'date'}
                                        id={'expirationDate'}
                                        onClick={event => event.currentTarget.showPicker()}
                                        position={'absolute'}
                                        inset={'0'}
                                        w={'100%'}
                                        h={'100%'}
                                        visibility={'hidden'}
                                        opacity={'0'}
                                        isDisabled={notificationForm.isSubmitting}
                                        {...notificationForm.getFieldProps('expirationDate')}/>
                                    <Tooltip
                                        hasArrow
                                        label={notificationForm.errors.expirationDate}
                                        bg={'red.500'}
                                        placement={'top'}
                                        isDisabled={notificationForm.isSubmitting}
                                        isOpen={!!notificationForm.errors.expirationDate}>
                                        <Text
                                            cursor={'pointer'}
                                            userSelect={'none'}
                                            {...dateButtonProps as TextProps}
                                            color={'#1f4d7f'}>{`до ${moment(notificationForm.values.expirationDate).format('DD MMMM YYYY г. (dddd)')}`}</Text>
                                    </Tooltip>
                                </FormLabel>
                            </Flex>
                            <Button
                                isLoading={notificationForm.isSubmitting}
                                onClick={notificationForm.submitForm}
                                {...submitButtonStyleProps}>{mode === 0 ? 'Создать уведомление' : 'Сохранить изменения'}</Button>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default NotificationEditorModal