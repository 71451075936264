import {ChakraProps, HStack, IconButton, Stack, Text, Tooltip} from "@chakra-ui/react";
import React, {useMemo} from "react";
import PencilIcon from "./icons/PencilIcon";
import RemoveIcon from "./icons/RemoveIcon";
import {User} from "../entities/User";
import KeyIcon from "./icons/KeyIcon";

interface AccountItemProps extends ChakraProps {
    account: User
    onEdit?: (account: User) => void
    onGeneratePassword?: (account: User) => void
    onRemove?: (account: User) => void
}

const AccountItem = ({account, onEdit, onGeneratePassword, onRemove, ...props}: AccountItemProps) => {
    //initial props
    const {id, fullName, department, role} = useMemo(() => account, [account])

    //build view
    return (
        <HStack spacing={'8'} px={'3'} py={'2'} userSelect={'none'} {...props}>
            <Text w={'110px'} color={'gray.600'} fontSize={'sm'} textAlign={'center'}>{role === 'Admin' ? 'Администратор' : 'Сотрудник'}</Text>
            <Stack flex={'1'} spacing={'0'} userSelect={'text'}>
                <Text color={'gray.700'} fontSize={'md'} fontWeight={'medium'}>{fullName}</Text>
                <Text color={'gray.400'} fontSize={'sm'}>{department}</Text>
            </Stack>
            <Text flex={'1'} color={'gray.400'} fontSize={'sm'} textAlign={'center'} userSelect={'text'}>{id}</Text>
            <HStack spacing={'3'}>
                <Tooltip hasArrow label={'Редактировать уведомление'} placement={'start'}>
                    <IconButton
                        variant={'unstyled'}
                        role={'group'}
                        size={'ms'}
                        icon={
                            <PencilIcon
                                stroke={'gray.200'}
                                _groupHover={{stroke: '#1f4d7f'}}
                                transition={'ease-out 250ms'}/>
                        }
                        aria-label={'Редактировать уведомление'}
                        onClick={() => onEdit && onEdit(account)}
                        _active={{transform: 'scale(1.1)'}}
                        transition={'ease-out 250ms'}/>
                </Tooltip>
                <Tooltip hasArrow label={'Сгенерировать новый пароль'} placement={'start'}>
                    <IconButton
                        variant={'unstyled'}
                        role={'group'}
                        size={'ms'}
                        icon={<KeyIcon
                            stroke={'gray.200'}
                            _groupHover={{stroke: 'orange.500'}}
                            transition={'ease-out 250ms'}
                        />}
                        aria-label={'Сгенерировать новый пароль'}
                        onClick={() => onGeneratePassword && onGeneratePassword(account)}
                        _active={{transform: 'scale(1.1)'}}
                        transition={'ease-out 250ms'}/>
                </Tooltip>
                <Tooltip hasArrow label={'Удалить уведомление'} placement={'start'}>
                    <IconButton
                        variant={'unstyled'}
                        role={'group'}
                        size={'ms'}
                        icon={<RemoveIcon
                            stroke={'gray.200'}
                            _groupHover={{stroke: 'red.500'}}
                            transition={'ease-out 250ms'}
                        />}
                        aria-label={'Удалить уведомление'}
                        onClick={() => onRemove && onRemove(account)}
                        _active={{transform: 'scale(1.1)'}}
                        transition={'ease-out 250ms'}/>
                </Tooltip>
            </HStack>
        </HStack>
    )
}

export default AccountItem