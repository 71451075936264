import {ChakraProps, Flex, Grid, IconButton, Stack, Text, Tooltip} from "@chakra-ui/react";
import {Notification} from "../entities/Notification";
import React, {useMemo} from "react";
import moment from "moment-ru";
import PencilIcon from "./icons/PencilIcon";
import RemoveIcon from "./icons/RemoveIcon";
import {shortFullName} from "../utils/environments";

interface NotificationItemProps extends ChakraProps {
    notification: Notification
    onEdit?: (notification: Notification) => void
    onRemove?: (notification: Notification) => void
    enableEditButton?: boolean
    enableDeleteButton?: boolean
}

const NotificationItem = ({notification, onEdit, onRemove, enableEditButton = false, enableDeleteButton = false, ...props}: NotificationItemProps) => {
    //initial props
    const {header, description, author, publicationDate, expirationDate} = useMemo(() => notification, [notification])
    const isActual = useMemo(() => !moment(notification.expirationDate).isBefore(moment(), 'day'), [notification])

    //build view
    return (
        <Grid
            gridTemplateColumns={{base: 'max-content 1fr max-content', lg: 'max-content 1fr max-content max-content'}}
            gridTemplateRows={{base: 'max-content max-content', lg: 'max-content'}}
            gridGap={{base: '4', lg: '8'}}
            p={'3'}
            alignItems={'center'}
            userSelect={'none'}
            {...props}>
            <Stack spacing={'0'} alignItems={'center'}>
                <Text color={isActual ? 'gray.600' : 'gray.300'} fontSize={'sm'}>{moment(publicationDate).format('DD.MM.YYYY')}</Text>
                <Text color={isActual ? 'gray.300' : 'gray.200'} fontSize={'xs'}>{`до ${moment(expirationDate).format('DD.MM.YYYY')}`}</Text>
            </Stack>
            <Stack gridColumn={{base: '1/-1', lg: 'unset'}} gridRow={{base: '1', lg: 'unset'}} spacing={'0'}>
                <Text color={isActual ? '#1f4d7f' : 'gray.300'} fontSize={'md'} fontWeight={'medium'} userSelect={'text'}>{header}</Text>
                <Text color={isActual ? 'gray.600' : 'gray.200'} fontSize={'sm'} userSelect={'text'}>{description}</Text>
            </Stack>
            <Flex flexDir={{base: 'row', lg: 'column'}} gap={'1'} alignItems={'center'} justifyContent={'end'}>
                {enableEditButton &&
                    <Tooltip hasArrow label={'Редактировать уведомление'} placement={'start'}>
                        <IconButton
                            variant={'unstyled'}
                            role={'group'}
                            size={'ms'}
                            icon={
                                <PencilIcon
                                    stroke={'gray.200'}
                                    _groupHover={{stroke: '#1f4d7f'}}
                                    transition={'ease-out 250ms'}/>
                            }
                            aria-label={'Редактировать уведомление'}
                            onClick={() => onEdit && onEdit(notification)}
                            _active={{transform: 'scale(1.1)'}}
                            transition={'ease-out 250ms'}/>
                    </Tooltip>
                }
                {enableDeleteButton &&
                    <Tooltip hasArrow label={'Удалить уведомление'} placement={'start'}>
                        <IconButton
                            variant={'unstyled'}
                            role={'group'}
                            size={'ms'}
                            icon={<RemoveIcon
                                stroke={'gray.200'}
                                _groupHover={{stroke: 'red.500'}}
                                transition={'ease-out 250ms'}
                            />}
                            aria-label={'Удалить уведомление'}
                            onClick={() => onRemove && onRemove(notification)}
                            _active={{transform: 'scale(1.1)'}}
                            transition={'ease-out 250ms'}/>
                    </Tooltip>
                }
            </Flex>
            <Stack spacing={'0'} maxW={'100px'} alignItems={'end'}>
                <Text color={isActual ? 'gray.600' : 'gray.300'} fontSize={'sm'} textAlign={'end'}>{shortFullName(author.fullName)}</Text>
                <Text color={isActual ? 'gray.300' : 'gray.200'} fontSize={'xs'} textAlign={'end'}>{author.department}</Text>
            </Stack>
        </Grid>
    )
}

export default NotificationItem